import React from 'react';
import './Footer.css';
import linkedin from '../../images/footer-social-media-linkedin.svg';
import telegram from '../../images/footer-social-media-telegram.svg';

function Footer() {
    return (
        <footer className="footer" id="contacts">
            <h2 className="footer__title">Наши контакты</h2>  
             
            <div className="footer__social-media">
                <a className="footer__social-media-link" href="https://www.linkedin.com/company/executive-search-russia" rel="noreferrer" target="_blank"><img className="footer__social-media-icon" alt="Линкедин" src={linkedin}></img></a>
                <a className="footer__social-media-link" href="https://t.me/hortoninternationalrussia" rel="noreferrer" target="_blank"><img className="footer__social-media-icon" alt="Телеграм" src={telegram}></img></a>
            </div>

            <div className="footer__other-contacts">
                <h3 className="footer__other-contacts-title">HORTON INTERNATIONAL MOSCOW</h3>
                <ul className="footer__contacts-list">
                    <li className="footer__contacts-item">
                        <div className="footer__contacts-item-icon footer__contacts-item-icon_address"></div>
                        <p className="footer__contacts-item-text">115054, Россия, Москва, ул. Дубининская, д.53, с. 5</p>
                    </li>
                    <li className="footer__contacts-item">
                        <div className="footer__contacts-item-icon footer__contacts-item-icon_phone"></div>
                        <p className="footer__contacts-item-text">+ 7 495 229 47 74</p>
                    </li>
                    <li className="footer__contacts-item">
                        <div className="footer__contacts-item-icon footer__contacts-item-icon_email"></div>
                        <p className="footer__contacts-item-text">moscow@hortoninternational.com</p>
                    </li>
                </ul>
            </div>
        </footer>
    )
}

export default Footer;