import React from 'react';
import './Advantages.css';
import medal from '../../images/advantages-medal.svg';
import lamp from '../../images/advantages-lamp.svg';
import hands from '../../images/advantages-hands.svg';
import person from '../../images/advantages-person.svg';

function Advantages() {
    return (
        <section className="section section_advantages-content">
        <h2 className="section__title" id="advantages">Преимущества</h2>
        <div className="advantages__section">
            <div className="advantages__mountains"></div>

            <ul className="advantages__list">
                <li className="advantages__item">
                    <img className="advantages__icon" alt="Медаль" src={medal}></img>
                    <p className="advantages__text">Стремление обеспечить наивысшее качество</p>
                </li>
                <li className="advantages__item">
                    <img className="advantages__icon" alt="Лампочка" src={lamp}></img>
                    <p className="advantages__text">Креативный подход к&nbsp;бизнес задачам</p>
                </li>
                <li className="advantages__item">
                    <img className="advantages__icon" alt="Рукопожатие" src={hands}></img>
                    <p className="advantages__text">Приверженность тесному и&nbsp;активному сотрудничеству</p>
                </li>
                <li className="advantages__item">
                    <img className="advantages__icon" alt="Деловой человек" src={person}></img>
                    <p className="advantages__text">Мыслим глобально, действуем локально</p>
                </li>
            </ul>
        </div>
    </section>
    )
}

export default Advantages;