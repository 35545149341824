import React from 'react';
import './Main.css';
import Anticrisis from '../Anticrisis/Anticrisis';
import AboutUs from '../AboutUs/AboutUs';
import Advantages from '../Advantages/Advantages';
import Solutions from '../Solutions/Solutions';

function Main() {
    return (
        <main className="content">
            <Anticrisis />
            <AboutUs />
            <Advantages />
            <Solutions />
        </main>
    )
}

export default Main;