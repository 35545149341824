import React from 'react';
import './NavBar.css';

function NavBar() {
    return (
            <nav className="navbar__nav">
                <ul className="navbar__list">
                    <li className="navbar__item"><a href="/#specialization" className="navbar__link">Специализация</a></li>
                    <li className="navbar__item"><a href="/#about" className="navbar__link">О нас</a></li>
                    <li className="navbar__item"><a href="/#advantages" className="navbar__link">Преимущества</a></li>
                    <li className="navbar__item"><a href="/#solutions" className="navbar__link">Решения</a></li>
                    <li className="navbar__item"><a href="/#contacts" className="navbar__link">Контакты</a></li>
                </ul>
            </nav>
    )
}

export default NavBar;