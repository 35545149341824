import React from 'react';
import './Solutions.css';

function Solutions() {
    return (
        <section className="section section_solutions-content">
            <h2 className="section__title section__title_solutions" id="solutions">Решения</h2>
            <div className="solutions__mountains"></div>
            <div className="solutions__section">
                <div className="solutions__graph"></div>
                <div className="solutions__graph-text solutions__graph-text_position_top-center">
                    <p className="solutions__graph-item solutions__graph-item_highlighted">ПОИСК</p>
                    <p className="solutions__graph-item">Топ Менеджеров</p>
                </div>
                <div className="solutions__graph-text solutions__graph-text_position_right-top">
                    <p className="solutions__graph-item solutions__graph-item_highlighted">ОТЧЕТ</p>
                    <p className="solutions__graph-item">по&nbsp;рекомендациям</p>
                </div>
                <div className="solutions__graph-text solutions__graph-text_position_right-center">
                    <p className="solutions__graph-item solutions__graph-item_highlighted">МЕТОДОЛОГИЯ</p>
                    <p className="solutions__graph-item solutions__graph-item_highlighted">HORTON</p>
                </div>
                <div className="solutions__graph-text solutions__graph-text_position_right-bottom">
                    <p className="solutions__graph-item solutions__graph-item_highlighted">ТЕСТИРОВАНИЕ&nbsp;И&nbsp;ОЦЕНКА</p>
                    <p className="solutions__graph-item">кандидатов</p>
                </div>
                <div className="solutions__graph-text solutions__graph-text_position_bottom-center">
                    <p className="solutions__graph-item solutions__graph-item_highlighted">ПОДДЕРЖКА</p>
                    <p className="solutions__graph-item">работодателя&nbsp;в&nbsp;процессе адаптации&nbsp;кандидата</p>
                </div>
                <div className="solutions__graph-text solutions__graph-text_position_left-top">
                    <p className="solutions__graph-item solutions__graph-item_highlighted">ИССЛЕДОВАНИЕ</p>
                    <p className="solutions__graph-item">рынка труда</p>
                </div>
                <div className="solutions__graph-text solutions__graph-text_position_left-center">
                    <p className="solutions__graph-item solutions__graph-item_highlighted">ПРОВЕРКА</p>
                    <p className="solutions__graph-item">деловой&nbsp;репутации</p>
                </div>
                <div className="solutions__graph-text solutions__graph-text_position_left-bottom">
                    <p className="solutions__graph-item solutions__graph-item_highlighted">ОБЗОР</p>
                    <p className="solutions__graph-item">деятельности&nbsp;компаний</p>
                </div>
            </div>
        </section>
    )
}

export default Solutions;