import React from 'react';
import './AboutUs.css';

function AboutUs() {
    return (
        <section className="section section_about-us-container">
            <h2 className="section__title section__title_about-us" id="about">О нас</h2>
            <div className="about-us__mountains"></div>
            <div className="about-us__section">
                <div className="about-us__text-box">
                    <p className="about-us__text">Мы&nbsp;предоставляем первоклассные решения, которые помогают организациям создавать наиболее эффективные команды.</p>
                    <p className="about-us__text">Наш сервис executive search точен и&nbsp;своевременен, высокоэффективен и&nbsp;специально разработан для того, чтобы помочь Клиентам достичь своих бизнес-целей.</p>
                </div>
                
                <div className="about-us__graph-box">
                    <div className="about-us__headers">
                        <p className="about-us__header">ГЛОБАЛЬНО</p>
                        <p className="about-us__header">ЛОКАЛЬНО</p>
                    </div>

                    <div className="about-us__global">
                        <div className="about-us__graph-text">
                            <p className="about-us__graph-item about-us__graph-item_highlighted">1978</p>
                            <p className="about-us__graph-item">Год основания компании</p>
                        </div>
                        <div className="about-us__graph-text">
                            <p className="about-us__graph-item about-us__graph-item_highlighted">40</p>
                            <p className="about-us__graph-item">Офисов</p>
                        </div>
                        <div className="about-us__graph-text">
                            <p className="about-us__graph-item about-us__graph-item_highlighted">30</p>
                            <p className="about-us__graph-item">Стран</p>
                        </div>
                    </div>

                    <div className="about-us__local">
                        <div className="about-us__graph-text about-us__graph-text_theme_light">
                            <p className="about-us__graph-item about-us__graph-item_highlighted">2006</p>
                            <p className="about-us__graph-item">Год открытия Московского офиса</p>
                        </div>
                        <div className="about-us__graph-text about-us__graph-text_theme_light">
                            <p className="about-us__graph-item about-us__graph-item_highlighted">150+</p>
                            <p className="about-us__graph-item">Довольных клиентов</p>
                        </div>
                        <div className="about-us__graph-text about-us__graph-text_theme_light">
                            <p className="about-us__graph-item about-us__graph-item_highlighted">600+</p>
                            <p className="about-us__graph-item">Успешно реализованных проектов</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default AboutUs;