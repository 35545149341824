import React from 'react';
import './Anticrisis.css';

function Anticrisis() {
    return (
        <section className="section section_anticrisis-content">
            <h2 className="section__title" id="specialization">Специализация</h2>
            <div className="anticrisis__section">
                <div className="anticrisis__text-block anticrisis__text-block_position_left">
                    <p className="anticrisis__text">Быстрый поиск</p>
                    <p className="anticrisis__text anticrisis__text_highlited">CEO, CFO, HRD, IT</p>
                </div>
                <div className="anticrisis__text-block anticrisis__text-block_position_top-right">
                    <p className="anticrisis__text anticrisis__text_highlited">ПРОВЕРКА</p>
                    <p className="anticrisis__text">рекомендаций</p>
                </div>
                <div className="anticrisis__text-block anticrisis__text-block_position_bottom-right">
                    <p className="anticrisis__text anticrisis__text_highlited">ОЦЕНКА</p>
                    <p className="anticrisis__text">потенциала сотрудников</p>
                </div>
                <div className="anticrisis__graph"></div>                  
            </div>
        </section>
    )
}

export default Anticrisis;