import React from 'react';
import './Header.css';
import logo from '../../images/header-logo.png';
import NavBar from '../NavBar/NavBar';
import mountains from "../../images/header-background.jpg";

function Header() {
    return (
        <header className="header">
            <div className="header__background">
                <img className="header__mountains" src={mountains} alt="Горы"></img>
            </div>
            <img className="header__logo" src={logo} alt="Логотип" />
            <div className="header__title-frame"></div>
            <div className="header__title-box">
                <h1 className="header__title">EXECUTIVE SEARCH</h1>
                <p className="header__title-slogan">вершина - не предел</p>
            </div>
            <NavBar />
        </header>
    )
}

export default Header;